import React from "react"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <Container>
      <h1 style={{marginTop: "3rem", marginBottom: "3rem"}}>
        <i className="fas fa-fw fa-exclamation-triangle"></i>
        Not found
      </h1>
      <p>We couldn't find the page you were looking for.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
